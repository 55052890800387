.fullpage {
    padding: 0px !important;
}
.DraftEditor-root {
    max-height: 200px;
    border: 1px solid #F1F1F1;
    overflow: hidden scroll;
}
.rdw-editor-main {
    height: 200px !important;
    overflow: auto;
    box-sizing: border-box;
}
.MuiButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #bdbdbd;
    border-color: #ffffff !important;
}
.midBlock::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .midBlock::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 20px;

  }
   
  /* Handle */
  .midBlock::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 20px;

  }
  
  /* Handle on hover */
  .midBlock::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .demo-editor {
    background: #fff;
    height: 130px !important;
}